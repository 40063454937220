
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { iterable } from '@/utils/array';
import { getTotalMinutes } from '@/utils/gantt';

const MINUTES_PER_DAY = 60 * 24;

@Component({
  methods: {
    getTotalMinutes,
  },
})
export default class ZoomSelector extends Vue {
  @Prop({ required: true })
  private value!: [number, number];

  @Prop({ required: true })
  private defaultZoom!: [Date, Date];

  private static readonly DEFAULT_SLIDER_START_VALUE = 0;

  private startTimestamp: moment.Moment = moment.utc().subtract(24, 'hours');

  private mounted() {
    this.initializeTimestamps();
    this.initializeValue();
  }

  @Watch('defaultZoom')
  private watchDefaultZoom() {
    this.initializeTimestamps();
    this.initializeValue();
  }

  private initializeTimestamps() {
    this.startTimestamp = moment(this.defaultZoom[0]);
  }

  private initializeValue() {
    this.$emit('input', [
      ZoomSelector.DEFAULT_SLIDER_START_VALUE,
      getTotalMinutes(this.defaultZoom),
    ]);
  }

  private get sliderTicks(): number[] {
    // For purely visual reasons, if it's already past 10 or later, the first tick
    // will be two hours later sharp. This is, at 9:10 the first marker is 11.

    const minutesToOClock =
      60 - this.startTimestamp.minutes() + (this.startTimestamp.minutes() >= 10 ? 60 : 0);
    const tickAmount = 8;

    // Here, we use MINUTES_PER_DAY instead of totalMinutes so that all hours are rounded up.
    // totalMinutes will usually be MINUTES_PER_DAY + 5, so the ticks can be a minute or two off,
    // which doesn't look as pretty.
    return iterable(tickAmount).map((x) => minutesToOClock + (x * MINUTES_PER_DAY) / tickAmount);
  }

  private formatZoomValue(value: number): string {
    return moment(this.startTimestamp).local().add(value, 'minutes').format('HH:mm');
  }
}
