
import moment from 'moment';
import { Prop, Component, Vue } from 'vue-property-decorator';

@Component({})
export default class GanttTimelineRow extends Vue {
  @Prop({ required: true })
  private zoomValues!: [Date, Date];

  @Prop({ default: 6 })
  private markerAmount!: number;

  private get timeMarkers(): string[] {
    const dateRange = moment(this.zoomValues[1]).diff(this.zoomValues[0]);
    return [...new Array(this.markerAmount).keys()].map((x) =>
      moment(this.zoomValues[0])
        .add((dateRange / (this.markerAmount - 1)) * x, 'milliseconds')
        .format('HH:mm'),
    );
  }
}
