
import { GanttTableData } from '@/models/Charts/chartsData';
import { AbstractGanttChartTableGenerator } from '@/models/Charts/gantt/abstractGanttChartTableGenerator';
import { getStateColour } from '@/utils/color';
import { EChartsOption } from 'echarts';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  methods: {
    getStateColour,
  },
})
export default class GanttChartRow extends Vue {
  @Prop({ required: true })
  private data!: GanttTableData<any>[];

  @Prop({ required: true })
  private chartBounds!: [Date, Date];

  @Prop({ required: true })
  private generator!: AbstractGanttChartTableGenerator<any>;

  private get options(): EChartsOption {
    return this.generator.updateOptions(this.data, {
      ganttChartBounds: this.chartBounds,
    });
  }

  private get echartsUpdateOptions(): any {
    return { notMerge: true };
  }
}
