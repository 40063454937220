
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class StateFilter extends Vue {
  @Prop({ required: true })
  private value!: string[];

  @Prop({ required: true })
  private title!: string;

  @Prop({ required: true })
  private states!: string[];

  @Prop({ required: true })
  private getStateColor!: (state: string) => string;

  private toggleState(state: string) {
    if (this.isStateDisabled(state)) {
      this.$emit('input', [...this.value, state]);
    } else {
      this.$emit(
        'input',
        this.value.filter((x) => x !== state),
      );
    }
  }

  private isStateDisabled(state: string): boolean {
    return !this.value.includes(state);
  }
}
